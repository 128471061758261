<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Loading
      v-if="classification != null"
      :active.sync="loading"
      :isFullPage="false"
    ></Loading>
    <h1>Portfólio Informação do Negócio</h1>
    <div
      class="p-grid p-col-12 p-md-12"
      :style="{ position: 'relative', minHeight: '200px' }"
      v-if="classification == null"
    >
      <Loading :active.sync="loading" :isFullPage="false"></Loading>
    </div>
    <div class="p-grid p-col-12 p-md-12">
      <div class="p-col-12 p-md-6" v-if="classification !== null">
        <form
          name="classification-business-form"
          class="p-col-12"
          @submit.prevent="saveClassification"
        >
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-mt-2">
              <span class="p-float-label">
                <InputText
                  type="text"
                  name="marketing_name"
                  v-model="classification.marketing_name"
                  :disabled="disabled()"
                />
                <label for="marketing_name">Nome Marketing</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-mt-3">
              <div class="p-d-flex">
                <div class="p-field p-col-12 p-md-2 p-mt-2">
                  <span
                    class="p-float-label"
                    :style="{ top: '-1rem', left: '-1.0rem', fontSize: '12px' }"
                  >
                    <label for="caseStudy">Caso de Estudo</label>
                  </span>
                  <InputSwitch
                    v-model="classification.case_study"
                    id="caseStudy"
                    inputId="caseStudy"
                    :name="'caseStudy'"
                    style="'top: 3px;"
                    :disabled="disabled()"
                  />
                </div>
                <div class="p-field p-col-12 p-md-2 p-mt-2">
                  <span
                    class="p-float-label"
                    :style="{ top: '-1rem', left: '-1.0rem', fontSize: '12px' }"
                  >
                    <label for="closed">Edição Finalizada</label>
                  </span>
                  <InputSwitch
                    v-model="classification.closed"
                    id="closed"
                    inputId="closed"
                    :name="'closed'"
                    style="'top: 3px;"
                    :disabled="disabled()"
                  />
                </div>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <span class="p-float-label">
                <InputText
                  v-if="disabled()"
                  type="text"
                  name="seller"
                  v-model="classification.business.seller"
                  :disabled="disabled()"
                />
                <Dropdown
                  v-else
                  name="seller_id"
                  :options="sellerList"
                  :filter="true"
                  :optionLabel="getSearchLabel"
                  :optionValue="'id'"
                  v-model="classification.seller_id"
                  v-bind:class="['form-control']"
                  :disabled="disabled()"
                  v-validate="'required'"
                >
                  <template #option="slotProps">
                    {{ slotProps.option.name }}
                  </template>
                </Dropdown>
                <label for="seller_id">Vendedor</label>
              </span>
              <small
                v-if="errors.has('seller_id')"
                class="p-error"
                role="alert"
              >
                Vendedor é obrigatório
              </small>
            </div>
            <div class="p-field p-col-12 p-mt-3 p-ml-1" v-if="disabled()">
              <span
                class="p-float-label"
                :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
              >
                <label for="solutions">Soluções</label>
              </span>
              <span
                v-if="!Array.isArray(classification.solutions)"
                class="p-ml-1"
                >Sem Soluções</span
              >
              <Tag
                v-for="solution in classification.solutions"
                :key="solution.id"
                class="p-mr-2"
                :value="solution.name"
              />
            </div>
            <span v-else class="p-fluid p-formgrid p-grid">
              <div
                class="p-field p-col-6 p-md-4 p-grid p-ml-1"
                v-for="type of solutionsTypeList"
                :key="type.id"
              >
                <div class="p-field p-col-12 p-md-12 p-mt-1">
                  <span class="p-float-label">
                    <label class="p-text-bold" for="solutions">{{
                      type.name
                    }}</label>
                  </span>
                <div class="p-field p-col-12 p-md-12 p-grid p-ml-1  p-mt-2">
                  <div
                    v-for="solution of solutionsList"
                    :key="solution.id"
                    class="p-col-12 p-md-12 p-mt-1"
                    v-show="solution.type_id == type.id"
                  >
                    <Checkbox
                      :id="solution.id"
                      name="solution"
                      :value="solution.id"
                      v-model="leadSolution"
                    />
                    <label :for="solution.id" class="p-ml-2">{{
                      solution.name
                    }}</label>
                  </div>
                </div>
                </div>
              </div>
            </span>
            <div class="p-field p-col-12 p-mt-3 p-ml-1" v-if="disabled()">
              <span
                class="p-float-label"
                :style="{ top: '-0.75rem', left: '-0.5rem', fontSize: '12px' }"
              >
                <label for="business_areas">Áreas de Negócio</label>
              </span>
              <span
                v-if="!Array.isArray(classification.business_areas)"
                class="p-ml-1"
                >Sem Áreas de Negócio</span
              >
              <Tag
                v-for="businessArea in classification.business_areas"
                :key="businessArea.id"
                class="p-mr-2"
                :value="businessArea.name"
              />
            </div>
            <span v-else>
              <div class="p-field p-col-12 p-md-12 p-mt-1">
                <span class="p-float-label">
                  <label for="businessArea">Áreas de Negócio</label>
                </span>
              </div>
              <div class="p-field p-col-12 p-md-12 p-grid p-ml-1">
                <div
                  v-for="businessArea of businessAreaList"
                  :key="businessArea.id"
                  class="p-col-2 p-md-3 p-mt-1"
                >
                  <Checkbox
                    :id="businessArea.id"
                    name="businessArea"
                    :value="businessArea.id"
                    v-model="leadBusinessAreas"
                  />
                  <label :for="businessArea.id" class="p-ml-2">{{
                    businessArea.name
                  }}</label>
                </div>
              </div>
            </span>
            <div class="p-field p-col-12 p-mt-3">
              <span class="p-float-label">
                <Textarea
                  type="text"
                  name="description_project"
                  :autoResize="true"
                  rows="5"
                  v-model="classification.description_project"
                  :disabled="disabled()"
                />
                <label for="description_project">Descrição do Projeto</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-mt-3">
              <span class="p-float-label">
                <Textarea
                  type="text"
                  name="marketing_action"
                  :autoResize="true"
                  rows="5"
                  v-model="classification.marketing_action"
                  :disabled="disabled()"
                />
                <label for="marketing_action">Ações de Marketing</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-mt-3">
              <span class="p-float-label">
                <Textarea
                  type="text"
                  name="actions_taken"
                  :autoResize="true"
                  rows="5"
                  v-model="classification.actions_taken"
                  :disabled="disabled()"
                />
                <label for="actions_taken">Ações Efetuadas</label>
              </span>
            </div>
            <div class="p-field p-col-12 p-mt-3">
              <span class="p-float-label">
                <InputText
                  type="text"
                  name="case_study"
                  v-model="classification.updated_at"
                  :disabled="true"
                />
                <label for="case_study">Ultima Atualizção</label>
              </span>
            </div>
            <div
              class="p-field p-col-12 p-md-6"
              v-for="file in filesList"
              :key="file.id"
            >
              <div v-if="file.mimeType == 'application/pdf'" class="pdf-viewer">
                <embed
                  :src="`data:${file.mimeType};base64,${file.file}`"
                  type="application/pdf"
                  width="100%"
                  height="300px"
                />
              </div>
              <div v-else style="text-align: center">
                <ImagePreview
                  width="300"
                  :src="`data:${file.mimeType};base64,${file.file}`"
                  :alt="file.file_origin_name"
                  preview
                />
              </div>
            </div>
            <div class="p-field p-col-12 p-md-12">
              <FileUpload
                :ref="'filesInput'"
                :name="'files[]'"
                chooseLabel="Adicionar Ficheiros"
                :multiple="true"
                :showUploadButton="false"
                :showCancelButton="false"
                :maxFileSize="2000000000"
              />
            </div>

            <div class="p-field p-col-12 p-md-2" v-if="disabled">
              <Button
                label="Guardar"
                icon="pi pi-save"
                class="p-button-success"
                @click="saveClassification"
              />
            </div>
          </div>
        </form>
      </div>

      <div class="p-col-12 p-md-6">
        <DataTable
          :value="businessList"
          :filters.sync="businessFilters"
          filterDisplay="row"
          :loading="loading"
          class="p-datatable-sm"
          :sortOrder="1"
          sortField="resume"
          responsiveLayout="scroll"
          removableSort
          sortMode="single"
          :scrollable="true"
        >
          <template #empty> Não existem Negócios. </template>
          <template #header>
            <div class="table-header">Negócios</div>
          </template>
          <Column sortable field="resume" header="Negócio" filterField="resume">
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column
            sortable
            field="seller.username"
            header="Vendedor"
            filterField="seller.username"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                class="p-column-filter"
                v-model="filterModel.value"
                @input="filterCallback()"
              />
            </template>
          </Column>
          <Column sortable field="sellerDate" header="Data Venda" />
          <Column v-if="disabled">
            <template #body="slotProps">
              <Button
                icon="pi pi-trash"
                class="p-button-sm p-button-rounded p-button-danger p-button-text"
                @click="removeBusiness(slotProps.data.ngstamp)"
              />
            </template>
          </Column>
        </DataTable>
        <div
          style="max-height: 60%"
          v-if="classification !== null && can('seeBusinessClassificationLogs')"
        >
          <divider />
          <DataTable
            :value="logs"
            :filters.sync="filters"
            filterDisplay="row"
            class="p-datatable-sm p-mt-3"
            :sortOrder="1"
            responsiveLayout="scroll"
            removableSort
            sortMode="single"
            :scrollable="true"
            scrollHeight="flex"
            :autoLayout="false"
            :loading="loadingLogs"
          >
            <template #empty>
              Esta classificação não tem registos de alterações
            </template>
            <template #loading> A carregar. Por favor Aguarde... </template>
            <template #header>
              <div class="table-header">Registo de Alterações</div>
            </template>
            <Column
              sortable
              field="date"
              header="Data"
              filterField="date"
              :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            >
            </Column>
            <Column
              sortable
              field="type"
              header="Tipo"
              filterField="type"
              :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
              :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            >
            </Column>
            <Column
              sortable
              field="user"
              header="Utilizador"
              filterField="user"
              :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
              :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
            >
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  type="text"
                  class="p-column-filter"
                  v-model="filterModel.value"
                  @input="filterCallback()"
                />
              </template>
            </Column>
            <Column
              sortable
              field="changes"
              header="Alterações"
              filterField="changes"
              :headerStyle="{ 'min-width': '50%', 'max-width': '50%' }"
              :filterHeaderStyle="{ 'min-width': '50%', 'max-width': '50%' }"
              :bodyStyle="{ 'min-width': '50%', 'max-width': '50%' }"
            >
              <template #body="slotProps">
                <span v-html="slotProps.data.changes"></span>
              </template>
              <template #filter="{ filterModel, filterCallback }">
                <InputText
                  type="text"
                  class="p-column-filter"
                  v-model="filterModel.value"
                  @input="filterCallback()"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import unidecode from "unidecode";
import Tag from "primevue/tag";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FilterMatchMode } from "primevue/api/";
import businessService from "../services/business.service";
import prospectService from "../services/prospect.service";
import crmCategoriesService from "../services/crmCategories.service";
export default {
  name: "portfolio",
  components: {
    Loading,
    Tag,
  },
  data() {
    return {
      loading: false,
      loadingLogs: false,
      classification: null,
      sellerList: [],
      solutionsList: [],
      solutionsTypeList: [],
      businessAreaList: [],
      businessList: [],
      filesList: [],
      leadSolution: [],
      leadBusinessAreas: [],
      logs: [],
      filters: {
        user: { value: null, matchMode: FilterMatchMode.CONTAINS },
        changes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      businessFilters: {
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "seller.username": { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  async created() {
    this.loading = true;
    this.sellerList = await prospectService.getSellers();
    this.solutionsList = await crmCategoriesService.getCategories();
    this.solutionsTypeList = await crmCategoriesService.getCategoriesTypes();
    this.businessAreaList = await businessService.getBusinessAreasList();
    this.classification = await businessService.getClassification(
      this.$route.params.id
    );

    this.classification.case_study =
      this.classification.case_study === 1 ? true : false;
    this.classification.closed =
      this.classification.closed === 1 ? true : false;
    if (
      this.classification.solutions != undefined &&
      this.classification.solutions != null
    ) {
      this.leadSolution = this.classification.solutions.map(
        (solution) => solution.id
      );
    }
    if (
      this.classification.business_areas != undefined &&
      this.classification.business_areas != null
    ) {
      this.leadBusinessAreas = this.classification.business_areas.map(
        (solution) => solution.id
      );
    }
    this.filesList = await businessService.getBusinessClassificationFiles(
      this.$route.params.id
    );
    this.loading = false;
    if (this.can("seeBusinessClassificationLogs")) {
      this.logs = await businessService.getClassificationLogs(
        this.$route.params.id
      );
    }

    this.businessList = await businessService.getClassificationBusiness(
      this.$route.params.id
    );
  },
  methods: {
    disabled() {
      if (this.can("alwaysEditBusiness")) {
        return false;
      }
      if (this.classification.closed) return true;
    },
    getSearchLabel(info) {
      return `${unidecode(info.name)}`;
    },
    saveClassification() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.loading = true;
        let formData = new FormData();
        formData.append("seller_id", parseInt(this.classification.seller_id));
        formData.append(
          "case_study",
          this.classification.case_study == true ? 1 : 0
        );
        formData.append("closed", this.classification.closed == true ? 1 : 0);
        formData.append(
          "marketing_action",
          this.classification.marketing_action
            ? this.classification.marketing_action
            : ""
        );
        formData.append(
          "actions_taken",
          this.classification.actions_taken
            ? this.classification.actions_taken
            : ""
        );
        formData.append(
          "description_project",
          this.classification.description_project
            ? this.classification.description_project
            : ""
        );
        formData.append(
          "marketing_name",
          this.classification.marketing_name
            ? this.classification.marketing_name
            : ""
        );
        formData.append("solutions", this.leadSolution.join(";"));
        formData.append("business_areas", this.leadBusinessAreas.join(";"));
        let files = this.$refs["filesInput"];

        if (files.files.length > 0) {
          files.files.forEach((file) => {
            formData.append("files[]", file);
          });
        }

        businessService
          .updateBusinessClassification(this.classification.id, formData)
          .then((response) => {
            if (!response) {
              this.loading = false;
              return this.$toast.add({
                severity: "error",
                summary: `Erro ao adicionar tentativa de agendamento`,
                detail: "",
                life: 3000,
              });
            }
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Classificação de Negócio atualizada com sucesso",
              life: 3000,
            });
            return window.location.reload();
          });
      });
    },
    removeBusiness(businessId) {
      this.$confirm.require({
        header: "Remover Negócio",
        message: "Tem a certeza que pretende remover este negócio?",
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          businessService
            .removeBusinessFromClassification(
              businessId,
              this.classification.id
            )
            .then((response) => {
              if (!response) {
                this.loading = false;
                return this.$toast.add({
                  severity: "error",
                  summary: `Erro ao remover negócio`,
                  detail: "",
                  life: 3000,
                });
              }
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Negócio removido com sucesso",
                life: 3000,
              });
              return window.location.reload();
            });
        },
      });
    },
  },
};
</script>
